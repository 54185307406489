import { css } from "@emotion/react"
import React from "react"
import Layout from "../../components/layout/Layout"
import Seo from "../../components/seo"
import btn from '../../images/referral-btn.jpg'

export default function Referral() {
  return (
    <Layout learnMore>
      <Seo title="Referral - Pikes Peak Oral Surgery & Dental Implant Center | Dental Implants and Wisdom Teeth Removals in Colorado Springs, CO" />
      <section className="container" style={{maxWidth: '800px'}}>
      <h1>Online Referral Form</h1>
<p>You may refer patients to our office by filling out our secure online Referral Form. After you have completed the form, please make sure to press the Complete and Send button at the bottom to automatically send us your information. The security and privacy of patient data is one of our primary concerns and we have taken every precaution to protect it.</p>
<p><a href="https://mysecurepractice.com/Truform/16bde67f-9180-4130-8273-c748cc22df97/Submission/Create" target="_blank"> <img src={btn} border="0" alt="online referral form" width="566px" style={{maxWidth: '100%' }} /> </a> </p>
      </section>
    </Layout>
  )
}
